import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const DonatePage = () => {

  return (
    <Layout>
      <Seo title="Donate" />
      <div className="bg-blue-50 py-10">
        <div className="relative">
          <div className="h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <StaticImage
              className="w-full h-full object-cover sm:rounded-r-xl shadow"
              src="../images/gallery/campaign2.jpeg"
              alt="Gayann DeMordaunt Campaign"
            />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-32">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <h2 className="text-base font-semibold uppercase tracking-wider text-blue-900">Donate</h2>
              <p className="mt-2 text-red-600 text-3xl font-extrabold tracking-tight sm:text-4xl">Help support change</p>
              <p className="mt-3 text-lg text-gray-900">
                Contribute to Gayann's campign to support lower taxes on Idaho families and businesses, reduced property taxes, and decreased government spending.
              </p>
              <div className="mt-8">
                <div className="inline-flex rounded-md shadow">
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top"><input name="cmd" type="hidden" value="_s-xclick" />
                    <input name="hosted_button_id" type="hidden" value="FCL4ANMVS9QHG" />
                    {/* <input alt="PayPal - The safer, easier way to pay online!" height="75" name="submit" src="http://demordaunt.com/wp-content/themes/DeMordaunt/images/donate.png" type="image" width="200" /> */}
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-blue-900 focus:bg-blue-900 focus:ring-blue-900"
                    >
                      Donate
                      {/* <ArrowCircleRightIcon className="-mr-1 ml-2 h-5 w-5 text-white" aria-hidden="true" /> */}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DonatePage;